/* src/App.css */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.compass {
  width: 200px;
  height: 200px;
  /* background: url('compass.png') no-repeat center; */
  background-size: cover;
}

.needle {
  width: 10px;
  height: 100px;
  background-color: red;
  position: absolute;
  top: 50px;
  left: 95px;
  transform-origin: bottom center;
  transition: transform 0.5s;
}